import { components } from '@casanova/casanova-common';

const { CellText } = components.ColumnsCells;

export default [
  {
    label: 'Marca',
    field: 'brand',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'Línea',
    field: 'line',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'Versión',
    field: 'version',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'Modelo',
    field: 'model',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'No. de serie',
    field: 'serialNumber',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'No. placa',
    field: 'plateNumber',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'Estatus',
    field: 'status',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'Subestatus',
    field: 'subStatus',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'Rol del vehículo',
    field: 'vehicleRol',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'Vehiculo principal:',
    field: 'primaryVehicle',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'Sustituye a la placa:',
    field: 'replaceVehicle',
    renderer: CellText,
    align: 'center',
  },
];

export const VEHICLES_ACTIONS = {
  CHANGE_OF_VEHICLE: {
    label: 'Cambio de vehículo',
    name: 'changeOfVehicle',
    disabled: false,
    options: [],
    showExpanded: false,
  },
  LOAN: {
    label: 'Sustituto',
    name: 'loan',
    disabled: false,
    options: [],
    showExpanded: false,
  },
};

export const VEHICLE_CARD_ROW_FIELDS = {
  brand: { bold: false },
  line: { bold: true },
  version: { bold: false },
  model: { bold: false },
  serialNumber: { bold: true },
  plateNumber: { bold: true },
  status: { bold: false },
  subStatus: { bold: false },
  vehicleRol: { bold: false },
  primaryVehicle: { bold: false },
  replaceVehicle: { bold: false },
};

export const getContextualMenu = ({ vehicleRol }) => {
  const contextualMenuToSend = [
    VEHICLES_ACTIONS.CHANGE_OF_VEHICLE,
    VEHICLES_ACTIONS.LOAN,
  ];

  if (vehicleRol !== 'Vehículo Actual') {
    return [];
  }

  return contextualMenuToSend;
};
