import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { CardSection, TextField } from '@casanova/casanova-common';

const CompanyAddress = ({ data = {} }) => {
  const documentProofType = _get(data, 'proofAddressType.name');
  const street = _get(data, 'street');
  const exteriorNumber = _get(data, 'exteriorNumber', '');
  const interiorNumber = _get(data, 'interiorNumber', '');
  const zipCode = _get(data, 'suburb.zipCode.code', '');
  const municipality = _get(data, 'suburb.city.municipality.name', '');
  const state = _get(data, 'suburb.city.municipality.state.name', '');
  const colony = _get(data, 'suburb.name', '');

  return (
    <CardSection noStatus upperCase title="Domicilio de la empresa">
      <div className="row mt-3">
        <div className="col-3 mb-2">
          <TextField
            noMb
            title="TIPO DE COMPROBANTE"
            value={documentProofType}
          />
        </div>
        <div className="col-3 mb-2">
          <TextField noMb title="CALLE" value={street} />
        </div>
        <div className="col-3 mb-2">
          <TextField
            noMb
            title="N. EXTERIOR"
            value={exteriorNumber.toString()}
          />
        </div>
        <div className="col-3 mb-2">
          <TextField
            noMb
            title="N. INTERIOR"
            value={interiorNumber.toString()}
          />
        </div>
        <div className="col-3 mt-1">
          <TextField
            noMb
            title="CÓDIGO POSTAL"
            value={zipCode.toString().padStart(5, '0')}
          />
        </div>
        <div className="col-3 mt-1">
          <TextField noMb title="DELEGACIÓN / MUNICIPIO" value={municipality} />
        </div>
        <div className="col-3 mt-1">
          <TextField noMb title="ESTADO" value={state} />
        </div>
        <div className="col-3 mt-1">
          <TextField noMb title="COLONIA" value={colony} />
        </div>
      </div>
    </CardSection>
  );
};

CompanyAddress.propTypes = {
  data: PropTypes.object,
};

export default CompanyAddress;
