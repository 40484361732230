import { contractInfoTransformer } from '@contracts/sections/ContractInformation/transformer';
import {
  vehiclesTransformer,
  contractVehiclesTransformer as transformContractVehicles,
} from '@contracts/sections/Vehicles/transformer';

export const contractTransformer = (contractData) => {
  const contractInfo = contractInfoTransformer(contractData);
  const vehicleData = vehiclesTransformer(contractData);

  const transformedData = {
    ...contractInfo,
    ...vehicleData,
  };

  return transformedData;
};

export const contractVehiclesTransformer = ({ vehicles = [] }) => {
  const transformedData = transformContractVehicles({ vehicles });
  return transformedData;
};
