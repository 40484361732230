import types from './action-types';

export const resetContracts = (payload) => ({
  type: types.RESET,
  payload,
});

export function getContracts(payload) {
  return { type: types.GET_LIST, payload };
}

export function getContractsSucceed(payload) {
  return { type: types.GET_LIST_SUCCEED, payload };
}

export function getContractsFailed(payload) {
  return { type: types.GET_LIST_FAILED, payload };
}

export function getContractsFilters() {
  return { type: types.GET_FILTERS };
}

export function getContractsFiltersSucceed(payload) {
  return { type: types.GET_FILTERS_SUCCEED, payload };
}

export function getContractsFiltersFailed(payload) {
  return { type: types.GET_FILTERS_FAILED, payload };
}

export function setListOfContractsParams(payload) {
  return { type: types.SET_PARAMS, payload };
}

export function resetListOfContractsParams(payload) {
  return { type: types.RESET_PARAMS, payload };
}

export function setListOfContractsPage(payload) {
  return { type: types.SET_LIST_PAGE, payload };
}

export function closeResponseDialog() {
  return { type: types.CLOSE_RESPONSE_DIALOG };
}

export function saveContract(payload) {
  return { type: types.SAVE_CONTRACT, payload };
}

export function saveContractSucceed(payload) {
  return { type: types.SAVE_CONTRACT_SUCCEED, payload };
}

export function saveContractFailed(payload) {
  return { type: types.SAVE_CONTRACT_FAILED, payload };
}

export function getContractDetails(payload) {
  return { type: types.GET_CONTRACT_DETAILS, payload };
}

export function getContractDetailsSucceed(payload) {
  return { type: types.GET_CONTRACT_DETAILS_SUCCEED, payload };
}

export function getContractDetailsFailed(payload) {
  return { type: types.GET_CONTRACT_DETAILS_FAILED, payload };
}

export function cleanContractDetails(payload) {
  return { type: types.CLEAN_CONTRACT_DETAILS, payload };
}

export function getContractVehicles(payload) {
  return { type: types.GET_CONTRACT_VEHICLES, payload };
}

export function getContractVehiclesSucceed(payload) {
  return { type: types.GET_CONTRACT_VEHICLES_SUCCEED, payload };
}

export function getContractVehiclesFailed(payload) {
  return { type: types.GET_CONTRACT_VEHICLES_FAILED, payload };
}

export function setPageContractVehicles(payload) {
  return { type: types.SET_PAGE_CONTRACT_VEHICLES, payload };
}

export function changeOfVehicle(payload) {
  return { type: types.CHANGE_OF_VEHICLE, payload };
}

export function changeOfVehicleSucceed(payload) {
  return { type: types.CHANGE_OF_VEHICLE_SUCCEED, payload };
}

export function changeOfVehicleFailed(payload) {
  return { type: types.CHANGE_OF_VEHICLE_FAILED, payload };
}

export function createLoan(payload) {
  return { type: types.CREATE_LOAN, payload };
}

export function createLoanSucceed(payload) {
  return { type: types.CREATE_LOAN_SUCCEED, payload };
}

export function createLoanFailed(payload) {
  return { type: types.CREATE_LOAN_FAILED, payload };
}

export function updateContract(payload) {
  return { type: types.UPDATE_CONTRACT, payload };
}

export function updateContractSucceed(payload) {
  return { type: types.UPDATE_CONTRACT_SUCCEED, payload };
}

export function updateContractFailed(payload) {
  return { type: types.UPDATE_CONTRACT_FAILED, payload };
}

export function closeResponseDialogUpdateContract() {
  return { type: types.CLOSE_RESPONSE_DIALOG_UPDATE_CONTRACT };
}

export function getManagers(payload) {
  return { type: types.GET_MANAGERS, payload };
}

export function getManagersSucceed(payload) {
  return { type: types.GET_MANAGERS_SUCCEED, payload };
}

export function getManagersFailed(payload) {
  return { type: types.GET_MANAGERS_FAILED, payload };
}

export function getCoordinators(payload) {
  return { type: types.GET_COORDINATORS, payload };
}

export function getCoordinatorsSucceed(payload) {
  return { type: types.GET_COORDINATORS_SUCCEED, payload };
}

export function getCoordinatorsFailed(payload) {
  return { type: types.GET_COORDINATORS_FAILED, payload };
}

export function getAdminFleets(payload) {
  return { type: types.GET_ADMIN_FLEETS, payload };
}

export function getAdminFleetsSucceed(payload) {
  return { type: types.GET_ADMIN_FLEETS_SUCCEED, payload };
}

export function getAdminFleetsFailed(payload) {
  return { type: types.GET_ADMIN_FLEETS_FAILED, payload };
}
