import _get from 'lodash/get';
import moment from 'moment-timezone';
import 'moment/locale/es';

import { YYYY_MM_DD, timeZone } from 'utils/constants';
import { isPermanentLicense } from '@customers/common/utils';

export const driverLicenseTransformer = (
  driverLicenseData = {},
  addressData = {},
  isForeign = false
) => {
  const antiquity = driverLicenseData?.sinceDate
    ? moment(driverLicenseData.sinceDate).tz(timeZone).format(YYYY_MM_DD)
    : '';

  const validityYear = _get(driverLicenseData, 'validityYear', '');

  const transformedValues = {
    licenseType: _get(driverLicenseData, 'driverLicenseType.uuid', ''),
    licenseNumber: driverLicenseData.number,
    antiquity: antiquity.toString(),
    expedition: driverLicenseData?.issuedYear,
    validityDriverLicense: validityYear,
    nationality: _get(driverLicenseData, 'nationality.uuid', ''),
    emissionState: !isForeign
      ? _get(driverLicenseData, 'issuingState.uuid', '')
      : '',
    licenseFrontSideUrl: _get(driverLicenseData, 'frontScanFile.presignedUrl'),
    licenseFrontSide: _get(driverLicenseData, 'frontScanFile.originalName'),
    licenseBackSideUrl: _get(driverLicenseData, 'backScanFile.presignedUrl'),
    licenseBackSide: _get(driverLicenseData, 'backScanFile.originalName'),
    isPermanentLicense: isPermanentLicense(validityYear),
  };

  return transformedValues;
};
