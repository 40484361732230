import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { MaskedInput as Input, FileInput } from '@casanova/casanova-common';
import Section from 'components/Section';
import {
  alphaMaskWithSpacesAndAccent,
  onlyLettersWithSpaces,
  numericMask,
  alphaMaskWithAmpersand,
} from 'utils/masks';
import { acceptFiles } from 'utils/files';
import { FORM_ACTIONS } from 'utils/constants/formActions';

const CompanyData = ({ action, isTender }) => {
  const { values } = useFormikContext();

  const showUrl = action !== FORM_ACTIONS.ADD;
  return (
    <Section title="INFORMACIÓN DE LA EMPRESA">
      <div className="row">
        <div className="col-4">
          <Input
            // mask={onlyLettersWithSpaces(70)}
            maskPlaceholder=""
            label="NOMBRE DE LA EMPRESA*"
            placeholder="NOMBRE DE LA EMPRESA*"
            name="companyName"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-4">
          <Input
            mask={alphaMaskWithAmpersand(12)}
            maskPlaceholder=""
            upperCase
            label="RFC*"
            placeholder="RFC*"
            name="companyRfc"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-4">
          <Input
            // mask={alphaMaskWithSpacesAndAccent(70)}
            maskPlaceholder=""
            label="RAZÓN SOCIAL*"
            placeholder="RAZÓN SOCIAL*"
            name="companySocialReason"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-4">
          <Input
            mask={numericMask(10)}
            maskPlaceholder=""
            label="TELÉFONO 1"
            placeholder="TELÉFONO 1"
            name="companyPhone"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-2">
          <Input
            mask={numericMask(5)}
            maskPlaceholder=""
            label="EXT"
            placeholder="EXT"
            name="companyPhoneExt"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-4">
          <Input
            mask={numericMask(10)}
            maskPlaceholder=""
            label="TELÉFONO 2"
            placeholder="TELÉFONO 2"
            name="companyPhoneTwo"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-2">
          <Input
            mask={numericMask(5)}
            maskPlaceholder=""
            label="EXT"
            placeholder="EXT"
            name="companyPhoneTwoExt"
            disabled={action === 'view'}
          />
        </div>
        {!isTender && (
          <div className="col-4">
            <FileInput
              url={showUrl && values.companyConstituentActUrl}
              displayText={values.companyConstituentAct}
              type="file"
              label="COPIA ACTA CONSTITUTIVA*"
              placeholder="COPIA ACTA CONSTITUTIVA*"
              name="companyConstituentAct"
              accept="application/pdf, image/jpeg, image/png"
              disabled={action === 'view'}
            />
          </div>
        )}
        <div className="col-4">
          <FileInput
            url={showUrl && values.companyCompyRecUrl}
            displayText={values.companyCompyRec}
            type="file"
            label="COPIA DE RFC*"
            placeholder="COPIA DE RFC*"
            name="companyCompyRec"
            accept={acceptFiles}
            disabled={action === 'view'}
          />
        </div>
        <div className="col-4">
          <FileInput
            url={showUrl && values.companyCouponFiscalUrl}
            displayText={values.companyCouponFiscal}
            type="file"
            label="CÉDULA FISCAL*"
            placeholder="CÉDULA FISCAL*"
            name="companyCouponFiscal"
            accept={acceptFiles}
            disabled={action === 'view'}
          />
        </div>
        {!isTender && (
          <div className="col-4">
            <FileInput
              url={showUrl && values.companyOpinionComplianceUrl}
              displayText={values.companyOpinionCompliance}
              type="file"
              label="OPINIÓN DE CUMPLIMIENTO*"
              placeholder="OPINIÓN DE CUMPLIMIENTO*"
              name="companyOpinionCompliance"
              accept={acceptFiles}
              disabled={action === 'view'}
            />
          </div>
        )}
        {!isTender && (
          <div className="col-4">
            <FileInput
              url={showUrl && values.companyRequestVehiclesUrl}
              displayText={values.companyRequestVehicles}
              type="file"
              label="SOLICITUD DE VEHÍCULOS*"
              placeholder="SOLICITUD DE VEHÍCULOS*"
              name="companyRequestVehicles"
              accept={acceptFiles}
              disabled={action === 'view'}
            />
          </div>
        )}
        {!isTender && (
          <div className="col-4">
            <FileInput
              url={showUrl && values.companyPowerAttorneryUrl}
              displayText={values.companyPowerAttornery}
              type="file"
              label="COPIA DE PODER NOTARIAL*"
              placeholder="COPIA DE PODER NOTARIAL*"
              name="companyPowerAttornery"
              accept={acceptFiles}
              disabled={action === 'view'}
            />
          </div>
        )}
        {!isTender && (
          <div className="col-4">
            <FileInput
              url={showUrl && values.companyLetterAuthorizationUrl}
              displayText={values.companyLetterAuthorization}
              type="file"
              label="CARTA DE AUTORIZACIÓN*"
              placeholder="CARTA DE AUTORIZACIÓN*"
              name="companyLetterAuthorization"
              accept={acceptFiles}
              disabled={action === 'view'}
            />
          </div>
        )}
      </div>
    </Section>
  );
};

CompanyData.propTypes = {
  action: PropTypes.string,
};

CompanyData.defaultProps = {
  action: 'add',
};

export default CompanyData;
