import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  CardSection,
  TextField,
  FileViewer,
  UserInfo,
} from '@casanova/casanova-common';
import { getTextColor } from 'utils/customers';
import { CUSTOMER_DETAIL_KEYS } from '@customers/common/constants';

const AddressDetail = ({ data, listOfInvalidsDocuments = [] }) => {
  const { street, exteriorNumber, interiorNumber, proofAddressPeriod } = data;
  const [invalidDocument, setInvalidDocument] = useState({});

  const documentStatus = _get(data, 'documentStatus.name', '');
  const documentProofType = _get(data, 'proofAddressType.name');
  const zipCode = _get(data, 'suburb.zipCode.code', '');
  const municipality = _get(data, 'suburb.city.municipality.name', '');
  const state = _get(data, 'suburb.city.municipality.state.name', '');
  const colony = _get(data, 'suburb.name', '');

  const statusColor = useMemo(
    () => getTextColor(invalidDocument ? 'rechazada' : documentStatus),
    [invalidDocument, documentStatus]
  );

  const files = useMemo(
    () =>
      !_isEmpty(data)
        ? [_get(data, 'proofAddressFile.presignedUrl', undefined)]
        : undefined,
    [data]
  );

  useEffect(() => {
    setInvalidDocument(
      listOfInvalidsDocuments.find(
        ({ documentKey = '' }) => documentKey === CUSTOMER_DETAIL_KEYS.ADDRESS
      )
    );
  }, [listOfInvalidsDocuments]);

  return (
    <CardSection status={statusColor} title="Domicilio" upperCase>
      <div className="row">
        <div className="col-7">
          <FileViewer files={files} />
        </div>
        <div className="col-5">
          <TextField
            title="Estatus"
            status={statusColor}
            value={documentStatus.toUpperCase()}
          />
          <div className="row">
            <div className="col-6">
              <TextField
                title="Tipo de comprobante"
                value={documentProofType}
              />
              <TextField title="Calle" value={street} />
              <TextField
                title="C&oacute;digo postal"
                value={`${zipCode.toString().padStart(5, '0')}`}
              />
              <TextField title="Delegación / Municipio" value={municipality} />
            </div>
            <div className="col-6">
              <TextField
                title="Periodo"
                value={invalidDocument ? 'Vencida' : proofAddressPeriod}
                status={invalidDocument ? 'danger' : null}
              />
              <div className="row">
                <div className="col-6">
                  <TextField title="N. exterior" value={exteriorNumber} />
                </div>
                <div className="col-6">
                  <TextField title="N. interior" value={interiorNumber} />
                </div>
              </div>
              <TextField title="Estado" value={state} />
              <TextField title="Colonia" value={colony} />
            </div>
          </div>
          <UserInfo {...data} />
        </div>
      </div>
    </CardSection>
  );
};

AddressDetail.propTypes = {
  values: PropTypes.object,
  data: PropTypes.object,
  listOfInvalidsDocuments: PropTypes.array,
};

AddressDetail.defaultProps = {
  values: {},
};

export default AddressDetail;
