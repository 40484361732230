import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { withForm } from '@casanova/casanova-common';
import { saveCustomerForeginer as saveCustomerForeigner } from 'store/customers/actions';
import config, { getSchema, editConfig } from '@customers/common/config';
import { CustomerForms } from '@customers/sections';
import { getFormatter } from '@customers/common/formatter';
import { getCustomerType } from '@customers/common/utils';
import { commonCustomerCatalogs } from 'utils/customers';
import {
  getQuotePath,
  isContractQuote as isContractQuoteRes,
} from 'utils/navigation';

export function CustomerCreator(props) {
  const {
    fetchCatalog,
    dirty,
    submitted,
    history,
    quoteId,
    isMoral,
    onlyFisic = false,
    getAllCatalogs = true,
    customersPreventChange,
    avalCustomer,
    initialValues,
    onUpdateForm,
    customer,
  } = props;

  const isReservation = !!history?.location?.pathname?.includes('reservations');
  const isContractQuote = isContractQuoteRes();

  const [shouldBlock, setShouldBlock] = useState(true);

  const handleNoBlock = useCallback(
    (block) => {
      setShouldBlock(block);
    },
    [setShouldBlock]
  );

  const nextPath = isReservation
    ? '/reservations/customer/<%= id %>/detail'
    : '/customers/<%= id %>/detail';

  const getPreventChange = () => {
    if (customersPreventChange || avalCustomer) {
      const preventChangeInReservation =
        !submitted && (isReservation || isContractQuote) && shouldBlock;
      const preventChangeInCustomers =
        dirty && shouldBlock && !submitted && !avalCustomer;

      return preventChangeInReservation || preventChangeInCustomers;
    }
    return false;
  };

  useEffect(() => {
    if (isReservation && !quoteId && !isContractQuote) {
      window.location.href = getQuotePath('one');
    }

    if (getAllCatalogs) {
      commonCustomerCatalogs(fetchCatalog);
    }
  }, [fetchCatalog, getAllCatalogs, isContractQuote]);

  useEffect(() => {
    if (customer?.data) {
      const data = {
        data: {
          ...customer?.data,
          birthDate: '',
          userInfo: {
            ...customer?.data?.userInfo,
          },
        },
      };
      onUpdateForm(data);
    }
  }, []);

  useEffect(() => {
    if (!initialValues.curp) {
      initialValues.birthDate = '';
      initialValues.antiquity = '';
    }
  }, [initialValues]);

  const formsProps = {
    ...props,
    preventChange: getPreventChange(),
    nextPath,
    handleNoBlock,
    isReservation,
    isJuridicalCustomer: isMoral,
    onlyFisic,
    avalCustomer,
  };

  return <CustomerForms {...formsProps} />;
}

export default (props) => {
  const { customerType, customerPersonType, tenderType } = props;

  const isForeigner = useMemo(() => getCustomerType({ customerType }), [
    customerType,
  ]);

  const isJuridical = useMemo(
    () =>
      getCustomerType({
        customerType: customerPersonType,
        validationType: 'moral',
      }),
    [customerPersonType]
  );

  const isTender = useMemo(
    () =>
      getCustomerType({
        customerType: customerPersonType,
        validationType: 'tender',
      }),
    [customerPersonType]
  );

  const formattedConfig = useMemo(() => {
    const dialogs = props?.customer?.data
      ? editConfig?.dialogs
      : config?.dialogs;
    return {
      ...config,
      submitForm: isForeigner ? saveCustomerForeigner : config.submitForm,
      formatter: getFormatter({ isForeigner, isJuridical, isTender }),
      schema: getSchema({
        isForeigner,
        isJuridical,
        isTender: { isTender, tenderType },
      }),
      dialogs,
    };
  }, [isForeigner, isJuridical, isTender, tenderType]);

  const Component = withForm({ config: formattedConfig })(CustomerCreator);

  return Component({ ...props, isForeigner, isMoral: isJuridical, isTender });
};
