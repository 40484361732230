import i18n from '@i18n';
import * as Yup from 'yup';
import {
  fileTypeValidation,
  dateValidation,
  shouldUpdateDocument,
  differentValue,
  dateValidationGreat,
  dynamicRequired,
} from 'utils/validations/common';
import {
  DRIVER_LICENSE_FIELDS,
  REQUIRED_MESSAGE,
  ALLOWED_FORMATS,
  FORMAT_TYPES_ALLOWED_MESSAGE,
  UPDATE_DOCUMENT_MESSAGE,
} from 'utils/constants';
import { CUSTOMER_TYPES } from '../../common/constants';
import { initialValues, FIELDS } from './config';

const validateFieldOfSection = dynamicRequired({ sectionKeys: initialValues });

const driverLicenseDocumentTest = Yup.mixed()
  .test('should-update-document', UPDATE_DOCUMENT_MESSAGE, function (value) {
    const { initialValues } = this.options.context;
    if (!initialValues) return true;

    const isForeign = this.parent.personType === CUSTOMER_TYPES.FOREIGNER;

    const fieldsToValidate = DRIVER_LICENSE_FIELDS.filter((field) =>
      isForeign ? field !== 'emissionState' && field !== 'expedition' : field
    ).concat(['isPermanentLicense']);

    return shouldUpdateDocument(
      value,
      fieldsToValidate,
      this.parent,
      initialValues
    );
  })
  .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
    fileTypeValidation(file, ALLOWED_FORMATS)
  );

export const driverLicenseProps = {
  licenseType: Yup.mixed().when(['customerType', 'isAval'], {
    is: (customerType, isAval) =>
      customerType === CUSTOMER_TYPES.FOREIGNER && !isAval,
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: validateFieldOfSection,
  }),
  licenseNumber: Yup.mixed().when(['customerType', 'isAval'], {
    is: (customerType, isAval) =>
      customerType === CUSTOMER_TYPES.FOREIGNER && !isAval,
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: validateFieldOfSection
      .max(20, i18n('ERROR__COMMONS__MAX_LENGTH', [20]))
      .min(1, i18n('ERROR__COMMONS__MIN_LENGTH', [1])),
  }),
  antiquity: Yup.mixed().when(
    [
      'licenseType',
      'licenseNumber',
      'nationality',
      'emissionState',
      'expedition',
      'licenseFrontSide',
      'licenseBackSide',
      'validityDriverLicense',
      'customerType',
      'isAval',
    ],
    {
      is: (
        licenseType,
        licenseNumber,
        nationality,
        emissionState,
        expedition,
        licenseFrontSide,
        licenseBackSide,
        validityDriverLicense,
        customerType,
        isAval
      ) =>
        licenseType ||
        licenseNumber ||
        nationality ||
        emissionState ||
        expedition ||
        licenseFrontSide ||
        licenseBackSide ||
        validityDriverLicense ||
        (customerType === CUSTOMER_TYPES.FOREIGNER && !isAval),
      then: dateValidation.required(REQUIRED_MESSAGE),
      otherwise: dateValidation,
    }
  ),
  expedition: Yup.string().nullable(),
  validityDriverLicense: Yup.mixed().when(['customerType', 'isAval'], {
    is: (customerType, isAval) =>
      customerType === CUSTOMER_TYPES.FOREIGNER && !isAval,
    then: differentValue(
      dateValidationGreat.required(REQUIRED_MESSAGE),
      'expedition',
      i18n('ERROR__CUSTOMERS__DRIVER_LICENSE__INVALID_VALIDTY_INFO')
    ),
    otherwise: differentValue(
      dynamicRequired({
        sectionKeys: initialValues,
        schema: dateValidationGreat,
      }),
      'expedition',
      i18n('ERROR__CUSTOMERS__DRIVER_LICENSE__INVALID_VALIDTY_INFO')
    ),
  }),
  nationality: Yup.mixed().when(['customerType', 'isAval'], {
    is: (customerType, isAval) =>
      customerType === CUSTOMER_TYPES.FOREIGNER && !isAval,
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: validateFieldOfSection,
  }),
  emissionState: Yup.mixed().when(['personType', 'customerType'], {
    is: (personType, customerType) => {
      const isNational = customerType === CUSTOMER_TYPES.NATIONAL;
      const isFisic = personType === CUSTOMER_TYPES.FISIC;
      return isNational && isFisic;
    },
    then: validateFieldOfSection,
    otherwise: Yup.string(),
  }),
  licenseFrontSide: Yup.mixed().when(
    [
      'licenseType',
      'licenseNumber',
      'nationality',
      'emissionState',
      'expedition',
      'validityDriverLicense',
      'customerType',
      'isAval',
    ],
    {
      is: (
        licenseType,
        licenseNumber,
        nationality,
        emissionState,
        expedition,
        validityDriverLicense,
        customerType,
        isAval
      ) =>
        licenseType ||
        licenseNumber ||
        nationality ||
        emissionState ||
        expedition ||
        validityDriverLicense ||
        (customerType === CUSTOMER_TYPES.FOREIGNER && !isAval),
      then: driverLicenseDocumentTest.required(REQUIRED_MESSAGE),
      otherwise: driverLicenseDocumentTest,
    }
  ),
  licenseBackSide: Yup.mixed().when(
    [
      'licenseType',
      'licenseNumber',
      'nationality',
      'emissionState',
      'expedition',
      'validityDriverLicense',
      'customerType',
      'isAval',
    ],
    {
      is: (
        licenseType,
        licenseNumber,
        nationality,
        emissionState,
        expedition,
        validityDriverLicense,
        customerType,
        isAval
      ) =>
        licenseType ||
        licenseNumber ||
        nationality ||
        emissionState ||
        expedition ||
        validityDriverLicense ||
        (customerType === CUSTOMER_TYPES.FOREIGNER && !isAval),
      then: driverLicenseDocumentTest.required(REQUIRED_MESSAGE),
      otherwise: driverLicenseDocumentTest,
    }
  ),
  isPermanentLicense: Yup.boolean(),
};

export const DriverLicenseSchema = Yup.object().shape({
  ...driverLicenseProps,
});

export default DriverLicenseSchema;
