import * as yup from 'yup';
import {
  REQUIRED_MESSAGE,
  PHONE_LENGTH,
  EMAIL_NOT_VALID,
} from '@casanova/casanova-common/lib/utils/constants/index.js';
import i18n from '@i18n';
import { minBetweenYearsValidation } from 'utils/validations/common';
import { CURP } from 'utils/constants/regularExpressions';

export const email = yup
  .string()
  .required(REQUIRED_MESSAGE)
  .email(EMAIL_NOT_VALID);

export const phoneNumber = yup
  .string()
  .required(REQUIRED_MESSAGE)
  .min(10, PHONE_LENGTH);

export const homeNumber = yup
  .string()
  .test('home-number-length', PHONE_LENGTH, (value) => {
    // If the value exists, ensure it has exactly 10 characters
    if (value && value.length > 0) {
      return value.length === 10;
    }
    // If the value doesn't exist, no validation is applied
    return true;
  });

export const minimalCustomerInformationProps = {
  names: yup.string(),
  lastName: yup.string(),
  secondLastName: yup.string(),
  birthDate: minBetweenYearsValidation.required(REQUIRED_MESSAGE),
  birthPlace: yup.string(),
  gender: yup.string(),
  phoneNumber,
  homeNumber,
  email,
};

const customerInformation = {
  names: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(2, i18n('ERROR__COMMONS__MIN_LENGTH', [2])),
  lastName: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(2, i18n('ERROR__COMMONS__MIN_LENGTH', [2])),
  secondLastName: yup.string(),
};

export const minimalImportantCustomerInformationProps = {
  ...customerInformation,
  phoneNumber,
  homeNumber,
  email,
};

export const curpProps = {
  curp: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .test(
      'invalid-curp-format',
      i18n('ERROR__CUSTOMERS__CURP__WRONG__FORMAT'),
      (value) => {
        if (!value) return true;
        return CURP.test(value);
      }
    )
    .min(18, i18n('ERROR__CUSTOMERS__CURP__MIN_LENGTH')),
};

export const customerPhoneAndEmail = {
  email: email.test(
    'unique-email',
    i18n('ERROR__COMMONS__EMAIL__NO_UNIQUE'),
    function validate(value) {
      if (!value) return true;
      const { isUniqueEmail } = this.parent;
      if (typeof isUniqueEmail === 'undefined') return true;
      return isUniqueEmail;
    }
  ),
  phoneNumber: phoneNumber.test(
    'unique-phone',
    i18n('ERROR__COMMONS__CELLPHONE__NO_UNIQUE'),
    function validate(value) {
      if (!value) return true;
      const { isUniquePhone } = this.parent;
      if (typeof isUniquePhone === 'undefined') return true;
      return isUniquePhone;
    }
  ),
};

export const customerInformationProps = {
  ...minimalCustomerInformationProps,
  ...curpProps,
  personType: yup.string().required(REQUIRED_MESSAGE),
  ...customerPhoneAndEmail,
};

export const customerInformationContactTender = {
  ...customerInformation,
  customerSector: yup.string().required(REQUIRED_MESSAGE),
};

export const CustomerInformationSchema = yup
  .object()
  .shape(customerInformationProps);

export default CustomerInformationSchema;

export const CurpSchema = yup.object().shape(curpProps);

export const EmailSchema = yup.object().shape({ email });

export const PhoneSchema = yup.object().shape({ phoneNumber });

export const customerForeignerSchema = {
  ...customerInformation,
  birthDate: minBetweenYearsValidation.required(REQUIRED_MESSAGE),
  gender: yup.string().required(REQUIRED_MESSAGE),
  originCountry: yup.string().required(REQUIRED_MESSAGE),
  ...customerPhoneAndEmail,
};
