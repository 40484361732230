import React from 'react';
import * as Yup from 'yup';
import { Icons } from '@casanova/casanova-common';
import { saveCustomer, editCustomer } from 'store/customers/actions';

import { REQUIRED_MESSAGE } from 'utils/validations';
// eslint-disable-next-line import/no-named-as-default
import CustomerFormatter, { CustomerEditFormatter } from './formatter';
import CustomerTransformer from './transformer';

import InvoiceDataConfig from '../sections/InvoiceData/config';
import { invoiceDataProps } from '../sections/InvoiceData/schema';

import CustomerInformationConfig from '../sections/CustomerInformation/config';
import {
  customerForeignerSchema,
  customerInformationProps,
  minimalImportantCustomerInformationProps,
  customerInformationContactTender,
} from '../sections/CustomerInformation/schema';

import PassportInformationConfig from '../sections/PassportInformation/config';
import { passportInformationProps } from '../sections/PassportInformation/schema';

import VisaInformationConfig from '../sections/VisaInformation/config';
import { visaInformationProps } from '../sections/VisaInformation/schema';

import countryEntranceTypeConfig from '../sections/CountryEntranceType/config';
import { countryEntranceTypeProps } from '../sections/CountryEntranceType/schema';

import AccommodationInformationConfig from '../sections/AccommodationInformation/config';
import { accommodationInformationProps } from '../sections/AccommodationInformation/schema';

import OfficialIdentificationConfig from '../sections/OfficialIdentification/config';
import { officialIdentificationProps } from '../sections/OfficialIdentification/schema';

import CompanyDataConfig from '../sections/CompanyData/config';
import {
  companyDataProps,
  companyTenderDataProps,
} from '../sections/CompanyData/schema';

import AddressConfig from '../sections/Address/config';
import { addressProps, addressPropsTender } from '../sections/Address/schema';

import DriverLicenseConfig from '../sections/DriverLicense/config';
import { driverLicenseProps } from '../sections/DriverLicense/schema';

import DriversInformationConfig from '../sections/DriversInformation/config';
import { driversInformationProps } from '../sections/DriversInformation/schema';

import { CUSTOMER_TYPES, TENDER_TYPES } from './constants';

export const nationalCustomerSchema = Yup.object().shape({
  ...customerInformationProps,
  ...officialIdentificationProps,
  ...companyDataProps,
  ...addressProps,
  ...driverLicenseProps,
  ...invoiceDataProps,
  ...driversInformationProps,
});

export const legalCustomerSchema = Yup.object().shape({
  ...customerInformationProps,
  ...officialIdentificationProps,
  ...companyDataProps,
  ...invoiceDataProps,
  ...addressProps,
  ...driversInformationProps,
});

export const tenderCustomerSchema = (tenderType) => {
  let shape = {
    ...customerInformationContactTender,
    ...invoiceDataProps,
    ...addressPropsTender,
  };
  if (tenderType === CUSTOMER_TYPES.JURIDICAL)
    shape = {
      ...shape,
      ...companyTenderDataProps,
    };

  return Yup.object().shape(shape);
};

export const foreignerSchema = Yup.object().shape({
  ...customerForeignerSchema,
  ...passportInformationProps,
  ...visaInformationProps,
  ...accommodationInformationProps,
  ...countryEntranceTypeProps,
  ...driverLicenseProps,
  ...invoiceDataProps,
});

export const minimalImportantCustomerInformation = Yup.object().shape({
  ...minimalImportantCustomerInformationProps,
});

const banValidation = Yup.string().when('ban', {
  is: true,
  then: Yup.string().required(REQUIRED_MESSAGE),
  otherwise: Yup.string().nullable(),
});

export const getSchema = ({ isForeigner, isJuridical, isTender }) => {
  if (isForeigner) {
    return foreignerSchema;
  }
  if (isTender.isTender) {
    return tenderCustomerSchema(isTender.tenderType);
  }
  return isJuridical ? legalCustomerSchema : nationalCustomerSchema;
};

export const config = {
  schema: nationalCustomerSchema,
  initialTouched: {
    ...CustomerInformationConfig.initialTouched,
    ...PassportInformationConfig.initialTouched,
    ...InvoiceDataConfig.initialTouched,
    ...VisaInformationConfig.initialTouched,
    ...countryEntranceTypeConfig.initialTouched,
    ...AccommodationInformationConfig.initialTouched,
    ...OfficialIdentificationConfig.initialTouched,
    ...CompanyDataConfig.initialTouched,
    ...AddressConfig.initialTouched,
    ...DriverLicenseConfig.initialTouched,
    ...DriversInformationConfig.initialTouched,
    contractNumber: true,
    reason: true,
    personType: true,
    tenderType: true,
    customerSector: true,
    isAval: true,
  },
  initialValues: {
    ...CustomerInformationConfig.initialValues,
    ...InvoiceDataConfig.initialValues,
    ...PassportInformationConfig.initialValues,
    ...VisaInformationConfig.initialValues,
    ...countryEntranceTypeConfig.initialValues,
    ...AccommodationInformationConfig.initialValues,
    ...OfficialIdentificationConfig.initialValues,
    ...CompanyDataConfig.initialValues,
    ...AddressConfig.initialValues,
    ...DriverLicenseConfig.initialValues,
    ...DriversInformationConfig.initialValues,
    contractNumber: '',
    reason: '',
    personType: CUSTOMER_TYPES.FISIC,
    tenderType: TENDER_TYPES.FISIC,
    customerSector: '',
    isAval: false,
  },
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  formatter: CustomerFormatter,
  transformer: CustomerTransformer,
  submitForm: saveCustomer,
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE REGISTRO',
      message:
        'Una vez cargada la información del cliente tendrás que editarlo manualmente\n¿Estás seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage:
        'El cliente se agregó de forma exitosa al catálogo, si deseas realizar algún cambio tendrás que buscarlo y editarlo manualmente.',
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a Clientes',
      errorLabel: 'Reintentar',
      cancelLabel: 'Cancelar',
      successPath: '/customers/',
    },
  },
};

export default config;

export const editSchema = Yup.object().shape({
  contractNumber: banValidation,
  reason: banValidation,
});

export const getSchemaCustomerEditor = (params) =>
  editSchema.concat(getSchema(params));

export const getSubmitFormCustomerEditor = ({ isForeigner, isJuridical }) => {
  if (isForeigner) {
    return () => {};
  }
  return isJuridical ? legalCustomerSchema : editCustomer;
};

export const editConfig = {
  ...config,
  schema: editSchema,
  disableOnDirty: true,
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: true,
  submitForm: editCustomer,
  formatter: CustomerEditFormatter,
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE CAMBIOS',
      message:
        'Algunos campos del cliente han sufrido cambios\n¿Estás seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage:
        'Los cambios se han guardado correctamente, si deseas realizar algún otro cambio sólo busca la opción Editar en el detalle del cliente.',
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Regresar',
      errorLabel: 'Reintentar',
      cancelLabel: 'Cancelar',
      successPath: './detail',
    },
  },
};

export const iconsResponseDialog = {
  IconsResponseDialog: {
    Success: () => <Icons.IcoUserPositive />,
    Error: () => <Icons.IcoUserAlert />,
    Warning: () => <Icons.IcoUserAlert />,
  },
};
