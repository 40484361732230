import _isEmpty from 'lodash/isEmpty';

export const existsChanges = (current, initial, reviewFields = []) => {
  const fieldsChanged = reviewFields.filter(
    (field) => current[field] !== initial[field]
  );
  return {
    fieldsChanged,
    withChanges: fieldsChanged.length > 0,
  };
};
