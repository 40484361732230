import { setListOfContractsParams } from 'store/contracts/actions';
import { YYYY_MM_DD, timeZone } from 'utils/constants';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import schema from './schema';
import store from 'store';

const unityName = {
  AP: 'Automóviles Particulares',
  DG: 'Devoluciones Gobierno',
  LS: 'Leasing',
  LC: 'Licitaciones',
  Z: 'Renta Diaria',
  RT: 'Renting',
  UT: 'Utilitario',
  AT: 'A2DAHT',
  LR: 'Licitaciones RD',
};

const initialTouched = {
  folio: true,
  startDate: true,
  endDate: true,
  juridicalPersonName: true,
  businessArea: true,
  amount: true,
  includedVehicles: true,
  branch: true,
};

const initialValues = {
  folio: '',
  startDate: '',
  endDate: '',
  juridicalPersonName: '',
  businessArea: '',
  amount: '',
  includedVehicles: '',
  branch: '',
};

const defractFolio = (folio) => {
  try {
    if (folio == undefined || folio == '')
      return { onlyNumber: '', businessAreaUuid: '' };
    const catalogsBusinessAreas = _get(
      store?.getState(),
      'commons.catalogsBusinessAreas.list',
      []
    );
    const unityType = folio.slice(0, 2).toUpperCase();
    const onlyNumber =
      folio.slice(2) == ''
        ? folio.slice(2)
        : folio.slice(2).replace(/^0+/g, '').padStart(3, '0');
    const businessAreaUuid = catalogsBusinessAreas?.find(
      (ba) => ba.name == unityName[unityType]
    ).uuid;
    return { onlyNumber, businessAreaUuid };
  } catch (error) {
    console.log(error);
  }
};

const formatter = (values) => {
  const { folio, startDate, endDate } = values;
  // const { onlyNumber, businessAreaUuid } = defractFolio(folio);

  return {
    ...values,
    // folio: onlyNumber,
    folio: folio == '' ? folio : folio.padStart(3, '0'),
    // businessArea:
    //   businessAreaUuid == '' ? values.businessArea : businessAreaUuid,
    startDate: startDate
      ? moment(startDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
      : '',
    endDate: endDate
      ? moment(endDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
      : '',
  };
};

export const config = {
  schema,
  initialTouched,
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
  withButtons: false,
  formatter,
  submitForm: setListOfContractsParams,
  dialogs: {
    confirmation: {},
    response: {},
  },
};
