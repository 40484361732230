import * as Yup from 'yup';
import i18n from '@i18n';
import {
  curpValidationTest,
  dynamicRequired,
  fileValidation,
} from 'utils/validations/common';
import { REQUIRED_MESSAGE } from '@casanova/casanova-common/lib/utils/constants/index.js';
import { DRIVERS_INFORMATION_FIELDS } from './config';
import { CUSTOMER_TYPES } from '../../common/constants';

const params = { parentFields: true };

const driversInformationDocumentTest = fileValidation({
  schema: dynamicRequired({
    ...params,
    schema: Yup.mixed().when('driverName', {
      is: (driverName) => driverName,
      then: Yup.mixed().required(REQUIRED_MESSAGE),
      otherwise: Yup.mixed(),
    }),
  }),
  fields: DRIVERS_INFORMATION_FIELDS,
});

const curpDriverProp = ({ parent }) =>
  dynamicRequired({
    ...params,
    schema: curpValidationTest(Yup.string()).test(
      'different-of-customer-curp',
      i18n(
        'ERROR__CUSTOMERS__AUTHORIZED_DRIVERS__CURP_DIFFERENT_OF_CUSTOMER_CURP'
      ),
      (driverCurp) => {
        const { curp = '' } = parent;
        if (!curp || !driverCurp) return true;
        return driverCurp !== curp;
      }
    ),
  }).test(
    'not-repeat-curp',
    i18n('ERROR__CUSTOMERS__AUTHORIZED_DRIVERS__DIFFERENT_CURP'),
    function (driverCurp) {
      const path = this.path.match(/\d+/);

      if (!path) return true;

      const index = Number(Object.values(path)[0]);

      if (!index) return true;

      const { driverAuthorizedData = [] } = parent;

      const driverWithSameCurp = driverAuthorizedData
        .filter((_driver, i) => i !== index)
        .some((driver) => driver.driverCurp === driverCurp);

      return !driverWithSameCurp;
    }
  );

export const curpDriverSchema = (context) =>
  Yup.object().shape({
    driverCurp: curpDriverProp(context),
  });

export const driverSchema = (context) =>
  Yup.object().shape({
    driverUuid: Yup.string(),
    driverName: Yup.string(),
    driverFirstName: Yup.string(),
    driverLastName: Yup.string(),
    driverBirthDate: Yup.string(),
    driverPlaceBirth: Yup.string(),
    driverGender: Yup.string(),
    driverFrontLicense: context.parent.isAval
      ? Yup.mixed()
      : context.parent.licenseType === undefined ||
        context.parent.personType === CUSTOMER_TYPES.JURIDICAL
      ? driversInformationDocumentTest.required(REQUIRED_MESSAGE)
      : driversInformationDocumentTest,
    driverBackLicense: context.parent.isAval
      ? Yup.mixed()
      : context.parent.licenseType === undefined ||
        context.parent.personType === CUSTOMER_TYPES.JURIDICAL
      ? driversInformationDocumentTest.required(REQUIRED_MESSAGE)
      : driversInformationDocumentTest,
    driverCurp: context.parent.isAval
      ? Yup.mixed()
      : context.parent.licenseType === undefined ||
        context.parent.personType === CUSTOMER_TYPES.JURIDICAL
      ? curpDriverProp(context).required(REQUIRED_MESSAGE)
      : curpDriverProp(context),
  });

export const driversInformationProps = {
  driverAuthorizedData: Yup.array().when(['curp'], (value, schema, context) =>
    Yup.array(driverSchema(context))
  ),
};

export const DriversAuthorizedInformationSchema = Yup.object().shape({
  ...driversInformationProps,
});

export default DriversAuthorizedInformationSchema;
