import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from '@i18n';
import {
  Table,
  withLayout,
  TableBottom,
  Section,
  useFirstRender,
} from '@casanova/casanova-common';
import { columns } from './columns';

function VehicleVirtualContracts({
  match,
  virtualContracts,
  vehicleVirtualContracts,
  setPageVehicleVirtualContracts,
  vehicleDetails = {},
  onlyFetchParams = true,
}) {
  const { params = {}, results = {} } = virtualContracts;

  const isFirstRender = useFirstRender();

  const vehicleUuid = useMemo(() => match.params.id, [match.params.id]);

  useEffect(() => {
    if (onlyFetchParams ? !isFirstRender : true) {
      vehicleVirtualContracts({
        params,
        vehicleUuid,
      });
    }
  }, [vehicleUuid, params, onlyFetchParams]);

  return (
    <Section title="CONTRATOS VIRTUALES">
      <Table
        emptyMessage={i18n('ERROR__COMMONS__NOT_FOUND')}
        dataList={results.list}
        columns={columns}
      />
      <TableBottom
        search={virtualContracts}
        onChange={setPageVehicleVirtualContracts}
        showButton={false}
      />
    </Section>
  );
}

VehicleVirtualContracts.propTypes = {
  onlyFetchParams: PropTypes.bool,
};

export default withLayout(VehicleVirtualContracts, { withLoader: false });
