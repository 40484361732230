import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import {
  MaskedInput as Input,
  FileInput,
  ConnectedSelect,
  Section,
  DatePickerV2,
} from '@casanova/casanova-common';
import { alphaMask } from '@casanova/casanova-common/lib/utils/masks';
import i18n from '@i18n';
import { acceptFiles } from 'utils/files';
import { EMISSION_AND_VALIDITY_VALIDATIONS } from '@customers/common/validations';
import { INNCODE_SCORE } from '@customers/common/constants';
import config from './config';

const { EMISSION, VALIDITY } = EMISSION_AND_VALIDITY_VALIDATIONS;

const OfficialIdentification = ({
  action,
  isJuridicalCustomer,
  innCodeScore,
  innCodeImages,
  innCodeIssueDate,
}) => {
  const { initialValues, values, setValues } = useFormikContext();

  const handleOnChangeIdentificationType = useCallback(
    (event) => {
      setValues({
        ...values,
        ...config.initialValues,
        identificationType: event.target.value,
      });
    },
    [values]
  );

  const disabledInput = action === 'view';
  const innCodeDisableFields = innCodeScore > INNCODE_SCORE.INE.OK;

  return (
    <Section
      title={i18n(
        isJuridicalCustomer
          ? 'CUSTOMERS__SECTION__REPRESENTATION'
          : 'CUSTOMERS__SECTION__OFFICIAL'
      )}
    >
      <div className="row">
        <div className="col-md-4">
          <ConnectedSelect
            disabled={disabledInput || innCodeDisableFields}
            label="Tipo de identificación*"
            name="identificationType"
            options="commons.identityDocumentTypeEntities"
            onChange={handleOnChangeIdentificationType}
          />
        </div>
        <div className="col-4">
          <Input
            mask={alphaMask(20)}
            maskPlaceholder=""
            label="Clave de identificación*"
            placeholder="Clave de identificación*"
            name="identificationCode"
            disabled={disabledInput || innCodeDisableFields}
          />
        </div>
        <div className="col-md-4">
          <DatePickerV2
            initialValue={initialValues.emissionYear || values.emissionYear}
            label="Año de Emisión*"
            placeholder="Año de Emisión*"
            name="emissionYear"
            disabled={
              disabledInput || (innCodeDisableFields && innCodeIssueDate)
            }
            minimumDate={EMISSION.MIN_DATE}
            maximumDate={EMISSION.MAX_DATE}
            formatDateValue
          />
        </div>
        <div className="col-md-4">
          <DatePickerV2
            initialValue={initialValues.validity || values.validity}
            placeholder="Vigencia*"
            label="Vigencia*"
            name="validity"
            disabled={disabledInput || innCodeDisableFields}
            minimumDate={VALIDITY.MIN_DATE}
            maximumDate={VALIDITY.MAX_DATE}
            formatDateValue
          />
        </div>
        <div className="col-12 col-md-4">
          <FileInput
            url={
              action !== 'add' ? values.identificationFrontSideUrl : undefined
            }
            displayText={values.identificationFrontSide}
            type="file"
            label="Frontal de la identificación*"
            placeholder="Frontal de la identificación*"
            name="identificationFrontSide"
            accept={acceptFiles}
            disabled={
              disabledInput ||
              (innCodeDisableFields && innCodeImages.croppedFrontID)
            }
          />
        </div>
        <div className="col-12 col-md-4">
          <FileInput
            url={
              action !== 'add' ? values.identificationBackSideUrl : undefined
            }
            displayText={values.identificationBackSide}
            type="file"
            label="Trasera de la identificación*"
            placeholder="Trasera de la identificación*"
            name="identificationBackSide"
            accept={acceptFiles}
            disabled={
              disabledInput ||
              (innCodeDisableFields && innCodeImages.croppedBackID)
            }
          />
        </div>
      </div>
    </Section>
  );
};

OfficialIdentification.propTypes = {
  action: PropTypes.string,
};

OfficialIdentification.defaultProps = {
  action: 'add',
};

export default OfficialIdentification;
