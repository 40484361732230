import { all, call, put, select } from 'redux-saga/effects';
import { showLoader, hideLoader } from 'store/commons/actions';
import {
  getContractsFiltersService,
  getListOfContractsService,
  postContract,
  getContractDetails,
  postChangeOfVehicle,
  postCreateLoan,
  putContract,
  getManagers,
  getCoordinators,
  getAdminFleets,
  fetchContractVehicles,
} from 'services/contracts';
import {
  getContractsSucceed,
  getContractsFailed,
  getContractsFiltersSucceed,
  getContractsFiltersFailed,
  saveContractSucceed,
  saveContractFailed,
  getContractDetailsSucceed,
  getContractDetailsFailed,
  getContractVehicles,
  getContractVehiclesSucceed,
  getContractVehiclesFailed,
  changeOfVehicleSucceed,
  changeOfVehicleFailed,
  createLoanSucceed,
  createLoanFailed,
  updateContractSucceed,
  updateContractFailed,
  getManagersSucceed,
  getManagersFailed,
  getCoordinatorsSucceed,
  getCoordinatorsFailed,
  getAdminFleetsSucceed,
  getAdminFleetsFailed,
} from './actions';

export function* getListOfContractsSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(getListOfContractsService, action.payload);
    yield put(getContractsSucceed(data));
  } catch (error) {
    yield put(getContractsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getContractsFiltersSaga() {
  try {
    yield put(showLoader());
    const data = yield call(getContractsFiltersService);
    yield put(getContractsFiltersSucceed(data));
  } catch (error) {
    console.log(error);
    yield put(getContractsFiltersFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveContractSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(postContract, action.payload);
    yield put(saveContractSucceed(data));
  } catch (error) {
    yield put(saveContractFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getContractDetailsSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(getContractDetails, action.payload);
    yield put(getContractDetailsSucceed(data));
    const loanAndTransferParams = {
      uuid: action.payload.uuid,
      withLoader: false,
    };
    const contractVehiclesParams = yield select(
      (state) => state.contracts.contractVehicles.params
    );
    yield all([
      getContractVehiclesSaga({
        payload: { params: contractVehiclesParams, ...loanAndTransferParams },
      }),
    ]);
  } catch (error) {
    yield put(getContractDetailsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getContractVehiclesSaga({ payload }) {
  const { withLoader = true } = payload;
  try {
    if (withLoader) yield put(showLoader());
    const data = yield call(fetchContractVehicles, {
      uuid: payload.uuid,
      params: payload.params,
    });
    yield put(getContractVehiclesSucceed(data));
  } catch (error) {
    yield put(getContractVehiclesFailed(error));
  } finally {
    if (withLoader) yield put(hideLoader());
  }
}

export function* changeOfVehicleSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(postChangeOfVehicle, action.payload);
    const contractDetailsData = yield call(getContractDetails, {
      uuid: action.payload.uuid,
    });
    yield put(getContractDetailsSucceed(contractDetailsData));
    yield put(changeOfVehicleSucceed(data));
  } catch (error) {
    yield put(changeOfVehicleFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* createLoanSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(postCreateLoan, action.payload);
    yield put(createLoanSucceed(data));
  } catch (error) {
    yield put(createLoanFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* updateContractSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(putContract, action.payload);
    yield put(updateContractSucceed(data));
  } catch (error) {
    yield put(updateContractFailed(error));
  } finally {
    yield put(hideLoader());
  }
}
export function* getManagersSaga(action) {
  try {
    const data = yield call(getManagers, action.payload);
    yield put(getManagersSucceed(data));
  } catch (error) {
    yield put(getManagersFailed(error));
  }
}

export function* getCoordinatorsSaga(action) {
  try {
    const data = yield call(getCoordinators, action.payload);
    yield put(getCoordinatorsSucceed(data));
  } catch (error) {
    yield put(getCoordinatorsFailed(error));
  }
}

export function* getAdminFleetsSaga(action) {
  try {
    const data = yield call(getAdminFleets, action.payload);
    yield put(getAdminFleetsSucceed(data));
  } catch (error) {
    yield put(getAdminFleetsFailed(error));
  }
}
