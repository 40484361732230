import * as yup from 'yup';
import moment from 'moment';
import { timeZone } from '@casanova/casanova-common/lib/utils/date';
import { REQUIRED_MESSAGE } from '@casanova/casanova-common/lib/utils/constants/index.js';
import { fieldSearch } from '@casanova/casanova-common/lib/utils/transformHelpers';
import { requiredSearch } from '@casanova/casanova-common/lib/utils/validations/fields';
import i18n from '@i18n';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { branch } from 'recompose';
import { alphaMaskWithSpacesAndAccent } from 'utils/masks';

export const AMOUNT_MAX = 999999999999999999999 * 10;

const getTimezoneDate = (date) => moment(date).tz(timeZone);

export const schema = yup.object().shape({
  manager: yup.string().required('El campo Gerente es obligatorio.'),
  coordinator: yup.string().required('El campo Coordinador es obligatorio.'),
  vehiclesAdmin: yup
    .string()
    .required('El campo Administrador de Flota es obligatorio.'),
  startDate: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .test(
      'min-start-date',
      'No puede ser menor a la fecha actual',
      (startDate) =>
        moment(startDate).tz(timeZone) >=
        moment().subtract(1, 'day').tz(timeZone)
    ),
  endDate: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .test(
      'min-end-date',
      'La fecha de fin debe ser mayor a la fecha de inicio',
      function minEndDate(endDate) {
        const { startDate } = this.parent;

        if (startDate) {
          return getTimezoneDate(endDate) > getTimezoneDate(startDate);
        }

        return Boolean(endDate);
      }
    ),
  maxAmount: yup
    .number()
    .required(REQUIRED_MESSAGE)
    .min(1, i18n('ERROR__COMMONS__NUMBER_AMOUNT__INVALID_MIN', [1]))
    .test(
      'max-amount-date',
      'El monto maximo, no puede ser menor al minimo',
      function maxAmountNumber(maxAmount) {
        const { minAmount } = this.parent;
        if (minAmount) {
          return maxAmount > minAmount;
        }

        return maxAmount !== undefined && maxAmount !== null;
      }
    ),
  customer: yup
    .string()
    .required(REQUIRED_MESSAGE) // Mensaje de campo requerido
    .min(3, i18n('ERROR__COMMONS__NUMBER_AMOUNT__INVALID_MIN', [3])), // Mínimo de 3 caracteres
  // .matches(alphaMaskWithSpacesAndAccent(100), i18n('ERROR__COMMONS__INVALID_CHARACTERS')), // Permitir letras, números, comas y espacios

  [fieldSearch('customer')]: yup.mixed().when('action', {
    is: (action) => action !== FORM_ACTIONS.EDIT,
    then: yup
      .string()
      .required(i18n('ERROR__COMMONS__REQUIRED_FIELD')) // Mensaje de campo requerido
      .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3])) // Mínimo de 3 caracteres
      // .matches(alphaMaskWithSpacesAndAccent(100), i18n('ERROR__COMMONS__INVALID_CHARACTERS'))  // Permitir letras, números, comas y espacios
      .test(
        'required-requester-search',
        i18n('ERROR__COMMONS__REQUIRED__ITEM_OF_LIST', ['un cliente']),
        requiredSearch('customer') // Test personalizado para validar si es necesario el item de la lista
      ),
    otherwise: yup.string(),
  }),
  minAmount: yup
    .number()
    .required(REQUIRED_MESSAGE)
    .min(1, i18n('ERROR__COMMONS__NUMBER_AMOUNT__INVALID_MIN', [1])),
  noContrato: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .max(15, 'El número de contrato no puede tener más de 15 caracteres'),
  folio: yup.string(),
  rfc: yup.string(),
  businessArea: yup.string().required(REQUIRED_MESSAGE),
  naturalName: yup.string(),
  // branch: yup.string().required(REQUIRED_MESSAGE),
  manager: yup.string().required(REQUIRED_MESSAGE),
  vehiclesAdmin: yup.string().required(REQUIRED_MESSAGE),
  coordinator: yup.string().required(REQUIRED_MESSAGE),
});
