import React, { useCallback, useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { withForm } from '@casanova/casanova-common';
import { Blank } from 'components';
import { usePreventChange } from 'hooks';
import i18n from '@i18n';
import { saveAvalCustomer } from 'store/customers/actions';
import { config } from '@customers/common/config';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import AvalCreator from './AvalCreator';

export default function AvalCreatorContainer(props) {
  const {
    setShow,
    show,
    setShowResponseDialog,
    setPreventChange,
    updateAvalInfo,
    history,
    action,
    getDetail,
    avalCustomer,
    endorsementUuid,
    avalCustomerByDetail,
  } = props;
  const [hide, setHide] = useState(false);
  const [hasFetchedDetails, setHasFetchedDetails] = useState(false);
  const [preventChange] = usePreventChange();

  const isView = action === FORM_ACTIONS.VIEW;

  const handleSuccess = useCallback(() => {
    setShow(false);
    setPreventChange(false);
  }, [setShow, setPreventChange]);

  const handleCancel = useCallback(() => {
    if (updateAvalInfo) {
      setShow(false);
    } else {
      history.push('/customers/');
    }
  }, [updateAvalInfo, setShow, history]);

  useEffect(() => () => setTimeout(() => setPreventChange(true), 1), [
    setPreventChange,
  ]);

  useEffect(() => {
    if (show) {
      setShowResponseDialog(false);

      return () => {
        setShowResponseDialog(true);
      };
    }
  }, [setShowResponseDialog, show]);

  useEffect(() => {
    if (!show) return;

    const hasNotFetchedDetails =
      _isEmpty(avalCustomerByDetail) && _isEmpty(avalCustomer.data);
    const hasNotFetched = hasNotFetchedDetails;
    const withValidId = Boolean(endorsementUuid);

    if (hasNotFetched && !hasFetchedDetails && withValidId) {
      getDetail({ id: endorsementUuid, avalCustomer: true });
      setHasFetchedDetails(true);
    }
  }, [
    isView,
    show,
    avalCustomer,
    avalCustomerByDetail,
    hasFetchedDetails,
    endorsementUuid,
  ]);

  const helpers = {
    setHide,
    hide,
    preventChange,
    onCancel: handleCancel,
    left: updateAvalInfo ? <Blank /> : undefined,
  };

  const formattedConfig = {
    ...config,
    initialValues: {
      ...config.initialValues,
      isAval: true,
    },
    submitForm: saveAvalCustomer,
    onSuccess: handleSuccess,
    redirectInSuccess: false,
    dialogs: {
      ...config.dialogs,
      response: {
        ...config.dialogs.response,
        successLabel: i18n('COMMONS__CONTINUE__TEXT'),
        successMessage: i18n('CUSTOMERS__AVAL_CREATOR__MODAL__SUCCESS'),
      },
    },
    schema: updateAvalInfo ? {} : config.schema,
    withButtons: false,
    btnCancelLabel: i18n(
      updateAvalInfo ? 'COMMONS__CLOSE__TEXT' : 'COMMONS__CANCEL__TEXT'
    ),
    showContinue: !updateAvalInfo,
    avalCustomer,
  };

  const Component = withForm({ config: formattedConfig })(AvalCreator);
  return Component({ ...props, ...helpers });
}
