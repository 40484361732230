import _get from 'lodash/get';
import moment from 'moment-timezone';
import 'moment/locale/es';
import { YYYY_MM_DD, timeZone } from 'utils/constants';

export const addressTransformer = (addressData = {}) => {
  const prePeriod = moment(addressData.proofAddressPeriod, 'MMMM yyyy').tz(
    timeZone
  );
  const code = _get(addressData, 'suburb.zipCode.code', '');

  return {
    street: addressData.street,
    indoorNumber: addressData.interiorNumber,
    outdoorNumber: addressData.exteriorNumber,
    zipCode: `${code.toString().padStart(5, '0')}`,
    initialZipCode: `${code.toString().padStart(5, '0')}`,
    colony: _get(addressData, 'suburb.uuid', ''),
    addressDocumentType: _get(addressData, 'proofAddressType.uuid'),
    period: prePeriod.isValid() ? prePeriod.format(YYYY_MM_DD) : 0,
    municipality: _get(addressData, 'suburb.city.municipality.uuid', ''),
    state: _get(addressData, 'suburb.city.municipality.state.uuid', ''),
    addressDocumentUrl: _get(addressData, 'proofAddressFile.presignedUrl'),
    addressDocument: _get(addressData, 'proofAddressFile.originalName'),
  };
};
