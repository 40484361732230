import qs from 'qs';
import { sendCustomRequest } from '@casanova/casanova-common/lib/utils/service';
import { cleanParams } from '@casanova/casanova-common/lib/utils/requests';
import { APIError } from '@casanova/casanova-common/lib/utils/exceptions';
import { priceQuoteRootV1 as root } from 'services/roots';
import {
  contractTransformer,
  transformContractFilters,
  transformContractVehicle,
} from './transformer';

// path, options, data, method = 'get'
// options.authToken (Poner TRUE)

export async function getBussinesAreaList() {
  // https://api-sara.wundertec.com/pricequotes/v1/catalogs/business-areas?sort=name&&isEnabled=true
  const endpoint = `${root}/catalogs/business-areas?sort=name&&isEnabled=true`;
  const response = await sendCustomRequest(endpoint, {}, undefined, 'GET');
  // const response = await sendCustomRequest(endpoint, options, body, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getListOfContractsService({ params = {} }, options = {}) {
  const queryParams = `?${qs.stringify(cleanParams(params))}`;
  const endpoint = `${root}/virtual-contracts${queryParams}`;
  const response = await sendCustomRequest(endpoint, options);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const result = response.data.data.content;
  result.forEach((el, i) => {
    const unityType = {
      'Automóviles Particulares': 'AP',
      'Devoluciones Gobierno': 'DG',
      Leasing: 'LS',
      Licitaciones: 'LC',
      'Renta Diaria': 'Z',
      Cortesia: 'CR',
      Apartado: 'AP',
      Subarrendados: 'SB',
      Renting: 'RT',
      Utilitario: 'UT',
      A2DAHT: 'AT',
      'Licitaciones RD': 'LR',
    };
    // eslint-disable-next-line no-param-reassign, no-undef
    result[i].folio = `${unityType[el.businessArea]}${el.folio.padStart(
      8,
      '0'
    )}`;
    // if (el.businessArea == 'Automóviles Particulares')
  });
  return response.data.data;
}

export async function getContractsFiltersService() {
  const url = `${root}/virtual-contracts/filter`;

  const response = await sendCustomRequest(url, {}, undefined, 'GET');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return transformContractFilters(response.data.data);
}

export async function postContract(body, options = {}) {
  const endpoint = `${root}/virtual-contracts`;
  const response = await sendCustomRequest(endpoint, options, body, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getContractDetails({ uuid }, options = {}) {
  const endpoint = `${root}/virtual-contracts/${uuid}`;
  const response = await sendCustomRequest(endpoint, options);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const { data = {} } = response.data;
  const transformedData = contractTransformer(data);
  return transformedData;
}

export async function postChangeOfVehicle(body, options = {}) {
  const endpoint = `${root}/virtual-contracts/${body.uuid}/permanent-change`;
  const response = await sendCustomRequest(endpoint, options, body, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function postCreateLoan(body, options = {}) {
  const endpoint = `${root}/virtual-contracts/${body.uuid}/temporary-change`;
  const response = await sendCustomRequest(endpoint, options, body, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function postAddCar(body, options = {}) {
  const endpoint = `${root}/virtual-contracts/add/vehicle`;
  const response = await sendCustomRequest(endpoint, options, body, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function postRemoveCar(body, options = {}) {
  const endpoint = `${root}/virtual-contracts/remove/vehicle`;
  const response = await sendCustomRequest(endpoint, options, body, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function putContract(body, options = {}) {
  const endpoint = `${root}/virtual-contracts`;
  const response = await sendCustomRequest(endpoint, options, body, 'put');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getManagers() {
  const endpoint = `${root}/catalogs/fleet`;
  const response = await sendCustomRequest(endpoint, {}, undefined, 'GET');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getCoordinators() {
  const endpoint = `${root}/catalogs/fleet/coordinators`;
  const response = await sendCustomRequest(endpoint, {}, undefined, 'GET');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getAdminFleets() {
  const endpoint = `${root}/catalogs/fleet/admin-fleets`;
  const response = await sendCustomRequest(endpoint, {}, undefined, 'GET');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function fetchContractVehicles(
  { uuid, params = {} },
  options = {}
) {
  const queryParams = `?${qs.stringify(cleanParams(params))}`;
  const endpoint = `${root}/virtual-contracts/${uuid}/vehicles${queryParams}`;
  const response = await sendCustomRequest(endpoint, options);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const contractVehicles =
    response.data?.data?.content?.length > 0
      ? response.data.data.content.map(transformContractVehicle)
      : [];
  return {
    ...response.data.data,
    content: contractVehicles,
  };
}
